import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    error: "",
};

const targetSettingUpdateStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_UPDATED:
            //console.log(`${new Date()}: targetSettingUpdateStatusPayload action ${action.type} `);

            return {
                loading: false,
                isSuccess: true,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_UPDATE:
            return {
                loading: true,
                isSuccess: false,
                error: action.payload.data.error
            };
        case ActionTypeList.TARGET_SETTING_EDIT_MODE_UPDATED:
            return initialState;
        default:
            return state;
    }
}

export default targetSettingUpdateStatusPayload;