import { ActionTypeList } from '../constants/index'

const initialState = {
    loading: false,
    data: {},
    message: "",
    error: "",
};

const accountSubscriptionPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_UPDATED:
            console.log(`${new Date()}: accountSubscriptionPayload action ${JSON.stringify(action)} `);

            return {
                loading: false,
                data: action.payload.data.data,
                message: action.payload.data.message,
                error: ""
            };
        case ActionTypeList.ACCOUNT_SUBSCRIPTION_UPDATE:
            return {
                loading: true,
                data: {},
                message: "",
                error: action.payload.data.error
            };
        default:
            return state;
    }
}

export default accountSubscriptionPayload;